import {useRef,useState,useCallback,useEffect} from 'react';
import {Button, Table, Spin, Modal, Col, Row,Form,Input,Select,DatePicker} from 'antd';
import Header from '../../../components/HeaderList';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import {ReactComponent as Lock} from '../../../assets/icons/lock.svg';
import {getColumnSearchProps} from '../../../utils/tables';
import styles from '../styles/UsuariosUI.module.css';
import React from 'react';
import { MaskedInput } from 'antd-mask-input';
import moment from 'moment';
import instance from "../../../api/Request";
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import {useSelector, useDispatch} from 'react-redux';
import {getAllContenedores,selectUsers} from '../../../redux/slices/users';

const {Option} = Select;

dayjs.extend(weekday)
dayjs.extend(localeData)

const UsuariosUI = props => {
  const searchInput = useRef(null);
  const [loading, handleLoading] = useState(true);
  const [fechaInicio, setFechaInicio] = useState(false);
  const [fechaFin, setFechaFin] = useState(false);
  const [fechaInicioSelect, setFechaInicioSelect] = useState(null);
  const [fechaFinSelect, setFechaFinSelect] = useState(null);
  const [loadingBuscar, handleLoadingBuscar] = useState(false);
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);

  const dateFormat = 'DD/MM/YYYY';
  const { RangePicker } = DatePicker;

  const onChangeFecha = (date, dateString) => {
    //console.log(date, dateString,"!!!!!");
    setFechaInicioSelect(date[0])
    setFechaFinSelect(date[1])
  };

  const {
    itemPass,
  } = props;

  const buscarFechas = async(fechaInicio,fechaFin) => {


    let today = new Date(fechaInicio);
    today.setHours(0); // establece la hora 
    today.setMinutes(0); // establece los minutos 
    today.setSeconds(0); // establece los segundos 


    let fechaNueva =  new Date(fechaFin);
    fechaNueva.setHours(23); // establece la hora a las 23:00
    fechaNueva.setMinutes(59); // establece los minutos a 59
    fechaNueva.setSeconds(59); // establece los segundos a 59

    handleLoadingBuscar(true);

    var formattedDate = moment(today).format(dateFormat);
    var formattedDateF = moment(fechaNueva).format(dateFormat);
    

    dispatch(getAllContenedores(formattedDate,formattedDateF))
    handleLoadingBuscar(false);

    
    /*
    const result = await getRutasHistorico(today,fechaNueva);
    console.log("result",result)
    if(result.ok){
      setRutas(result.listadoRutas)
    }else{
      message.error('¡Hubo un problema!');
    }
    handleLoadingBuscar(false);*/

  }

  useEffect(() => {
    const initialFetch = async () => {

      let today = new Date();
      let fechaNueva =  new Date(moment(today).add(-6, 'day'));

      var fechaInicio = new Date(fechaNueva.setHours(0,0,0,0));
      var fechaFinal = new Date(new Date().setHours(23,59,59,59));
      fechaFinal.setHours(23); // establece la hora a las 23:00
      fechaFinal.setMinutes(59); // establece los minutos a 59
      fechaFinal.setSeconds(59); // establece los segundos a 59

      var formattedDate = moment(fechaInicio).format(dateFormat);
      var formattedDateF = moment(fechaFinal).format(dateFormat);
      
      setFechaInicio(fechaInicio)
      setFechaFin(fechaFinal)

      setFechaInicioSelect(fechaInicio)
      setFechaFinSelect(fechaFinal)

      dispatch(getAllContenedores(formattedDate,formattedDateF))
      handleLoading(false);
    };
    initialFetch();
  }, [dispatch]);



  const save = async () => {
   
  

  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombreMotorista',
      align: 'left',
      ...getColumnSearchProps('nombreMotorista'),
    },
    {
      title: 'Teléfono',
      dataIndex: 'contactoMotorista',
      align: 'left',
      ...getColumnSearchProps('contactoMotorista'),
    },
    {
      title: 'Consolidador',
      dataIndex: 'consolidador',
      ...getColumnSearchProps('consolidador'),
    },{
      title: 'N° de contenedor',
      dataIndex: 'nContenedor',
      align: 'left',
      ...getColumnSearchProps('nContenedor'),
    },
    {
      title: 'Placas',
      dataIndex: 'placas',
      align: 'left',
      ...getColumnSearchProps('placas'),
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaCreado',
      align: 'left'
    },
  ];



  return (
    <div className={styles.view}>
      <Header
        title="Listado de ingreso de contenedores"
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <div style={{display:"flex",marginBottom:"20px"}}>
            <RangePicker style={{
                  width: "400px",
                }}
                format={dateFormat}
                defaultValue={[dayjs(fechaInicio, dateFormat), dayjs(fechaFin, dateFormat)]}
                onChange={onChangeFecha} />

                
            <Button
              style={{marginLeft:"20px"}}
              size="large"
              onClick={() => {
                buscarFechas(fechaInicioSelect,fechaFinSelect)
              }}
              loading={loadingBuscar}
              type={'primary'}
              className={styles.submit}>
                BUSCAR
            </Button>

                
          </div>
          <Table
            columns={columns}
            dataSource={users}
            rowKey="id"
            pagination={{ pageSize: 20 }}
          />
        </div>
      )}


    </div>
  );
};

export default UsuariosUI;
