import { Layout, Menu, Dropdown } from "antd";
import moment from "moment-timezone";
import "moment/locale/es";
import { Link, useLocation, Routes, Route } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo/logo_header.svg";
import { ReactComponent as Clock } from "../../../assets/icons/clock.svg";
import { ReactComponent as Account } from "../../../assets/icons/account.svg";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as Portada } from "../../../assets/navigation/cobertura.svg";


import styles from "../styles/DashboardUI.module.css";
import Usuarios from "../../usuarios/Main";

const { Header, Content, Sider } = Layout;

const DashboardUI = ({ closeSession, user, rol }) => {
  const location = useLocation();

  const NavItem = ({ to, label, Icon }) => {
    const path = location.pathname.split("/")[1];
    const base = `/${path}`;

    return (
      <Link
        to={to}
        className={`${styles.item} ${
          (base === to || (path === "historial" && to === "/")) && styles.active
        }`}
      >
        <Icon className={styles.icon} />
        <span>{label}</span>
      </Link>
    );
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={closeSession} danger>
        Cerrar sesión
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className={styles.layout}>
      
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          width={98}
          className={styles.sider}
        >
          <div className={styles.logoWrap}>
            <Logo className={styles.logo} />
          </div>
          <div className={styles.nav}>

            
          
        </div>
        </Sider>
      
      <Layout className={styles.layout}>
        <Header className={styles.header}>
          <div className={styles.headerInfo}>
            <span className={styles.title}>Panel Administrativo</span>
            <Logo className={styles.logoMobile} />
            <div className={styles.wrapDate}>
              <Clock />
              <span className={styles.date}>
                {moment()
                  .tz("America/El_Salvador")
                  .format("D [de] MMMM  -  hh:mm A")}
              </span>
            </div>
            <Dropdown overlay={menu}>
              <div className={styles.accountWrap}>
                <span className={styles.account}>
                  {user.nombre}
                </span>
                <ChevronDown className={styles.chevron} />
                <div className={styles.iconAccount}>
                  <Account />
                </div>
              </div>
            </Dropdown>
          </div>
        </Header>
        <Content className={styles.content}>
               <Routes>
                <Route path="/" element={<Usuarios />} />
              </Routes>
            
            
          
        </Content>
       
      </Layout>
    </Layout>
  );
};

export default DashboardUI;
