import instance from './Request';

export const getContenedores = async (fechaInicio,fechaFinal) => {
  const request = await instance();
  let data = await request
    .get('/ingreso_contenedores.php?fechaInicio='+fechaInicio+'&fechaFinal='+fechaFinal)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};