import { configureStore } from "@reduxjs/toolkit";
import user from "./slices/user";
import users from "./slices/users";


export const store = configureStore({
  reducer: {
    user,
    users
  },
});
