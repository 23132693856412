import {createSlice} from '@reduxjs/toolkit';
import {getContenedores} from '../../api/Users';

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
  },
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setUser: (state, action) => {
      const index = state.users.findIndex(el => el.id === action.payload.id);
      if (index > -1) {
        state.users[index] = action.payload;
      }
    },
    addUser: (state, action) => {
      state.users.push(action.payload);
    },
    removeUser: (state, action) => {
      const index = state.users.findIndex(el => el.id === action.payload);
      if (index > -1) {
        state.users.splice(index, 1);
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {setUsers, setUser, addUser, removeUser} = usersSlice.actions;

export const getAllContenedores = (fechaInicio,fechaFinal) => async dispatch => {
  try {
    const response = await getContenedores(fechaInicio,fechaFinal);

    // Eliminar el BOM de la cadena de texto
    let cleanedData = response.data.replace(/^\uFEFF/, '');
    console.log("cleanedData",cleanedData)

    // Parsear la respuesta a un objeto JSON
    let responseData = JSON.parse(cleanedData);
    if (responseData.status === 200) {

      dispatch(setUsers(responseData.registros));

    }
  } catch (e) {
    console.log("------",e)
  }
};

export const selectUsers = state => state.users.users;

export default usersSlice.reducer;
