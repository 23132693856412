import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {logout, selectUser} from '../../redux/slices/user';
import DashboardUI from './components/DashboardUI';


const Main = () => {
  const user =  useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const user =  {rol:1,nombre:"test"}

  useEffect(() => {


    /*if (isAccountant) {
      navigate('/historial');
    } else if (!isAdmin) {
      navigate('/');
    }*/

    //navigate('/');
  }, []);

  const closeSession = () => {
    dispatch(logout());
  };

  return (
    <DashboardUI closeSession={closeSession} user={user} rol={user.rol} />
  );
  
}

export default Main;
