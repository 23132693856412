import axios from "axios";

const getInstance = async () => {
  const timeout = 60000;
  const baseURL = "https://adminform.clgsv.com/api/"
  const request = axios.create({
    baseURL,
    timeout,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return request;
};

export default getInstance;
