import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message, Modal} from 'antd';
import UsuariosUI from './components/UsuariosUI';
import {getAllContenedores,selectUsers} from '../../redux/slices/users';


const Usuarios = () => {
  const [loading, handleLoading] = useState(true);
  //const [users, setUsers] = useState([]);

  const [item, setItem] = useState(null);
  const [itemPass, setItemPass] = useState(null);
  const [isModalDetails, handleIsModalDetails] = useState(false);
  const [isModalDetailsPass, handleIsModalDetailsPass] = useState(false);

  const dispatch = useDispatch();

  /*useEffect(() => {
    console.log("entro???")

    const initialFetch = async () => {
      console.log("entro 2???")
      const resultUsers = await getAllContenedores();
      console.log("entro 3???",resultUsers)
      if(resultUsers.status = "success"){
        setUsers(resultUsers.registros)
      }
      handleLoading(false);

    };

    initialFetch();
  }, [dispatch]);

  
  useEffect(() => {
   
  }, [dispatch]);*/


  const handleDetail = async (item) => {
    setItem(item)
    handleIsModalDetails(true)
  };

  const handleDetailPass = async (item) => {
    console.log("item",item)
    setItemPass(item)
    handleIsModalDetailsPass(true)
  };
  

  const handleCancelModalDetails = async () => {
    setItem(null)
    handleIsModalDetails(false)
  };

  const handleCancelModalDetailsPass = async () => {
    setItemPass(null)
    handleIsModalDetailsPass(false)
  };

  

  const newUser = async () => {
    var item = {}
    item.nuevo = true
    setItem(item)
    handleIsModalDetails(true)
    
    
  };

  return (
    <UsuariosUI
      item={item}
      itemPass={itemPass}
    />
  );
}

export default Usuarios;
