import {createSlice} from '@reduxjs/toolkit';
import {login, validateLogin} from '../../api/User';

const initialState = {
  info: localStorage.getItem('cdi_dash')
  ? JSON.parse(localStorage.getItem('cdi_dash'))
  : null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setInfo: (state, action) => {
      state.info = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {setInfo} = userSlice.actions;


export const validation = (response) => async dispatch => {
  try {
    await localStorage.setItem('cdi_dash', JSON.stringify(response));
    dispatch(setInfo(response));
    return {
      status: 'success',
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const logout = () => dispatch => {
  localStorage.removeItem('cdi_dash');
  dispatch(setInfo(null));
};

export const signIn = (email, password) => async dispatch => {
  try {
    const response = await login(email, password);
  
    // Eliminar el BOM de la cadena de texto
    let cleanedData = response.data.replace(/^\uFEFF/, '');

    // Parsear la respuesta a un objeto JSON
    let responseData = JSON.parse(cleanedData);

    // Ahora puedes acceder a la propiedad 'type'
    console.log(responseData.type);


    // Ahora puedes acceder al campo type
    console.log(responseData.type);

    console.log("response!!!!",responseData.status,responseData,response)
    
    if (responseData.status === 200) {
      if(responseData.error == true){
        return {
          status: 'error',
          type: responseData.type,
        };
      }else{
        return {
          status: 'success',
          infoUser: responseData.user,
        };
      }
    }else{
      return {
        status: 'error',
        type: 'unknown',
      };
    }
  } catch (e) {
    console.log("====",e)
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const selectUser = state => state.user.info;

export default userSlice.reducer;
